<template>
  <v-container grid-list-md>
    <update v-if="loaded" :id="updateId" :email="email" @emailError="(val) => lookupError = val"></update>
    <v-card v-else style="margin-bottom:5em">
      <v-card-title >Alumni Information and News Update</v-card-title>
      <v-card-text v-if="!emailChecked">
        <p>To update your contact information and/or submit an alumni update, please complete the fields below. If the information matches what we have on file, then a unique code will be sent to you via email. If you receive an error message, meaning our records do not match your current information, please contact the alumni office at <a href="mailto:alumni@covenant.edu" target="_blank">alumni@covenant.edu</a> so we can update it.</p>
        <v-form ref="form" v-model="valid">
          <v-text-field ref="emailRef" v-model="email" :rules="emailRules" label="Email Address" outlined @keydown="emailKeydown"></v-text-field>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="first" :rules="required" label="First Name" hint="Or preferred name" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="last" :rules="required" label="Last Name" hint="Or your maiden name" outlined></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="classYear" :rules="required" label="Class Year" hint="Full Year; i.e. 1999" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-alert v-if="emailError" style="margin:1em" color="error" icon="fas fa-exclamation-triangle" dark>The email address you entered was not found in any records matching the given name. Do you have another email address to try? If your email has changed and you no longer have access to your old email, please contact the Alumni Office (alumni@covenant.edu) directly so we can update your email address in the system.</v-alert>
        <v-btn @click="verifyEmail" style="margin-top:1em">{{ verifyingEmail ? 'Checking...' : 'Verify Email' }}</v-btn>
      </v-card-text>
      <v-card-text v-else-if="!loaded">
        <v-alert v-if="lookupError" color="error" style="margin:1em">There was an error looking up the entered code with your email. Please ensure that you entered the whole code correctly.</v-alert>
        <p v-else>An email has been sent to the email address you entered with a code. Please enter that code below to access your update form.</p>
        <v-text-field v-model="updateId" label="Unique Code" :rules="updateRules"></v-text-field>
        <v-alert v-if="loadError" style="margin:1em" color="error" icon="fas fa-exclamation-triangle" dark>The code did not match the information. Please make sure you copied it correctly from the email.</v-alert>
        <v-btn @click="loadUpdate" style="margin-top:1em">Load Update</v-btn>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import { ref } from '@vue/composition-api'
export default {
  components: {
    Update: () => import('@/components/alumni/Update')
  },
  setup (props, { root }) {
    const form = ref(null)
    const valid = ref(true)
    const emailChecked = ref(false)
    const emailRef = ref(null)
    const email = ref('')
    const emailRules = ref([
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
    ])
    const required = ref([
      v => !!v || 'Required'
    ])
    const verifyingEmail = ref(false)
    const emailError = ref(false)
    const lookupError = ref(null)

    const first = ref('')
    const last = ref('')
    const classYear = ref('')

    const service = root.$feathers.service('alumni/update')

    function emailKeydown (e) {
      if (e.key === 'Enter') {
        verifyEmail()
      }
    }

    function verifyEmail () {
      if (form.value.validate()) {
        verifyingEmail.value = true
        if (email.value !== '' && !emailRef.value.hasError && first.value !== '' && last.value !== '' && classYear.value !== '') {
          // Check to make sure email exists within the given document ID
          service.find({ query: { 'email.email': email.value.toLowerCase(), class: classYear.value, $and: [ { $or: [ { first: first.value }, { preferred: first.value } ] }, { $or: [ { last: last.value }, { maiden: last.value } ] } ] } }).catch((e) => {
            emailError.value = true
          }).then(({ data }) => {
            if (data.length > 0) {
              root.$feathers.service('system/email').create({
                from: 'alumni@covenant.edu',
                to: email.value.toLowerCase(),
                subject: 'Alumni Update Code',
                html: '<p>Below is your code for the alumni update system. Please copy the code in its entirety and paste it into the alumni update system to access your information. If you did not request this information, then please ignore this email.</p><p>' + data[0]._id + '</p>',
                text: 'Below is your code for the alumni update system. Please copy the code in its entirety and paste it into the alumni update system to access your information. If you did not request this information, then please ignore this email.\n\n' + data[0]._id
              })
              emailChecked.value = true
            } else {
              emailError.value = true
            }
          }).finally(() => {
            verifyingEmail.value = false
          })
        }
      }
    }

    const loading = ref(false)
    const loaded = ref(false)
    const updateId = ref('')
    const regex = /^[0-9a-f]{24}$/i
    const updateRules = ref([
      v => !!v || 'Required',
      v => v.length === 24 || 'Code must be 24 characters long',
      v => regex.test(v) || 'Invalid code format'
    ])
    const loadError = ref(false)

    function loadUpdate () {
      if (updateId.value.length === 24) {
        loading.value = true
        service.get(updateId.value, { query: { 'email.email': email.value.toLowerCase() } }).catch((e) => {
          loadError.value = true
        }).then((data) => {
          if (data == null) {
            loadError.value = true
          } else {
            loaded.value = true
          }
        }).finally(() => {
          loading.value = false
        })
      }
    }

    return {
      form,
      valid,
      emailChecked,
      emailRef,
      email,
      emailRules,
      required,
      verifyingEmail,
      emailError,
      lookupError,
      first,
      last,
      classYear,
      emailKeydown,
      verifyEmail,
      loading,
      loaded,
      updateId,
      updateRules,
      loadError,
      loadUpdate
    }
  }
}
</script>
